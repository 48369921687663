import PropTypes from "prop-types"
import React from "react"
import { Container, Box, Grid, Typography, makeStyles } from "@material-ui/core"
import InfoBall from "./InfoBall"
import colors from "../../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    background: colors.blue["-"],
    paddingTop: "2em",
    paddingBottom: "2em",
    textAlign: "left",
  },
  heading: {
    margin: "0.1em auto 0.25em",
    textTransform: "uppercase",
  },
  text: {
    margin: "1em auto",
  },
  infoballContainer: {
    margin: "1em auto",
  },
  button: {
    margin: "3rem auto 1rem",
  },
}))

const Profile = ({ heading, text, infoBalls, linkText, link }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container>
        {heading && (
          <Typography className={classes.heading} variant="h3" component="h2">
            {heading}
          </Typography>
        )}
        {text && (
          <Typography className={classes.text} variant="body1" component="p">
            {text}
          </Typography>
        )}

        {infoBalls && (
          <Grid container direction="row" justify="center" alignItems="center">
            {infoBalls
              .filter(b => {
                /* Only use valid & max 4 */
                return b.metric && b.description && b.icon
              })
              .slice(0, 4)
              .map((infoBall, idx) => (
                <Grid
                  item
                  key={idx}
                  className={classes.infoballContainer}
                  xs={12}
                  sm={6}
                  md={3}
                >
                  <InfoBall
                    icon={infoBall.icon}
                    metric={infoBall.metric}
                    desc={infoBall.description}
                    color={colors.blue[idx + 1] || colors.blue[4]}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </Box>
  )
}

Profile.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  infoBalls: PropTypes.array,
  linkText: PropTypes.string,
  link: PropTypes.string,
}

Profile.defaultProps = {
  infoBalls: [],
  linkText: "Katso lisää",
  link: "https://www.newsec.fi",
}

export default Profile
