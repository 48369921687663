import PropTypes from "prop-types"
import React from "react"
import * as R from "ramda"
import { GatsbyImage } from "gatsby-plugin-image"

import Box from "@material-ui/core/Box"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../../theme/colors"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    width: "220px",
    height: "220px",
    margin: "auto",
    overflow: "hidden",
  },
  ball: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    padding: "25% 12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  content: {},
  icon: {
    margin: "auto",
    height: "40px",
    width: "40px",
  },
  text: {
    color: colors.base.white,
    textTransform: "uppercase",
  },
}))

const InfoBall = ({ icon, metric, desc, color }) => {
  const iconFluid = R.path(["gatsbyImageData"], icon)
  let altText = "";
  if (iconFluid) {
    const len = iconFluid.images.sources[0].srcSet.split("/").length;
    altText = iconFluid.images.sources[0].srcSet.split("/")[len-1].split("?")[0].replace(".png","").replace("-"," ");
  }
  
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box
        className={classes.ball}
        style={{ background: color || colors.blue[4] }}
      >
        <Box className={classes.content}>
          {iconFluid && <GatsbyImage className={classes.icon} alt={altText} image={iconFluid} />}
          <Typography variant="h3" component="p" className={classes.text}>
            {metric.toUpperCase()}
          </Typography>
          <Typography variant="body2" component="p" className={classes.text}>
            {desc.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

InfoBall.propTypes = {
  icon: PropTypes.object.isRequired,
  metric: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default InfoBall
